import { request } from "../shared/RequestWrapper";

export const ToggleUserStatus = async ({ accountStatus, id }) => {
    const data = await request({
        url: "/user/ban-activate-user-account",
        method: "POST",
        data: {
            id,
            accountStatus,
        },
    });

    return data;
};

export const ResetPassword = async (body) => {
    const data = await request({
        url: "/trigger-reset-password",
        method: "POST",
        data: body,
    });

    return data;
};

export const ReVerifyUser = async (body) => {
    const data = await request({
        url: "/trigger-email-verification",
        method: "POST",
        data: body,
    });

    return data;
};
